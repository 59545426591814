"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.StoreDataService = void 0;
const store_http_client_1 = require("./api/store-http-client");
const bundle_1 = require("./bundle/bundle");
const unit_1 = require("./unit/unit");
const pack_1 = require("./virtual-currency/pack");
const virtual_currency_1 = require("./virtual-currency/virtual-currency");
const virtual_item_group_1 = require("./virtual-item-group/virtual-item-group");
const virtual_item_1 = require("./virtual-item/virtual-item");
class StoreDataService {
    static async getVirtualCurrencies({ projectId, auth, locale = StoreDataService.defaultLocale, }) {
        const response = await store_http_client_1.StoreApi.getVirtualCurrencyList({
            projectId,
            locale,
        }, auth);
        if ('errorCode' in response) {
            return Promise.reject(response);
        }
        const virtualCurrencies = response.items.map((item) => new virtual_currency_1.VirtualCurrency(item));
        return virtualCurrencies;
    }
    static async getVirtualCurrencyPacks({ projectId, auth, locale = StoreDataService.defaultLocale, }) {
        const response = await store_http_client_1.StoreApi.getVirtualCurrencyPackage({
            projectId,
            locale,
        }, auth);
        if ('errorCode' in response) {
            return Promise.reject(response);
        }
        const virtualCurrencyPacks = response.items.map((item) => new pack_1.VirtualCurrencyPack(item));
        return virtualCurrencyPacks;
    }
    static async getGames({ projectId, locale = StoreDataService.defaultLocale, }) {
        const response = await store_http_client_1.StoreApi.getGamesList({
            projectId,
            locale,
        });
        if ('errorCode' in response) {
            return Promise.reject(response);
        }
        const units = response.items.map((item) => new unit_1.Unit(item));
        return units;
    }
    static async getVirtualItems({ projectId, auth, locale = StoreDataService.defaultLocale, }) {
        const response = await store_http_client_1.StoreApi.getVirtualItems({
            projectId,
            locale,
        }, auth);
        if ('errorCode' in response) {
            return Promise.reject(response);
        }
        const virtualItems = response.items.map((item) => new virtual_item_1.VirtualItem(item));
        return virtualItems;
    }
    static async getVirtualItemsByGroup({ projectId, groupId, auth, locale = StoreDataService.defaultLocale, }) {
        const response = await store_http_client_1.StoreApi.getVirtualItemsByGroup({
            projectId,
            groupId,
            locale,
        }, auth);
        if ('errorCode' in response) {
            return Promise.reject(response);
        }
        const virtualItems = response.items.map((item) => new virtual_item_1.VirtualItem(item));
        return virtualItems;
    }
    static async getVirtualItemsGroup({ projectId, locale = StoreDataService.defaultLocale, }) {
        const response = await store_http_client_1.StoreApi.getVirtualItemsGroup({
            projectId,
            locale,
        });
        if ('errorCode' in response) {
            return Promise.reject(response);
        }
        const virtualItemsGroup = response.groups.map((item) => new virtual_item_group_1.VirtualItemGroup(item));
        return virtualItemsGroup;
    }
    static async getBundles({ projectId, auth, locale = StoreDataService.defaultLocale, }) {
        const response = await store_http_client_1.StoreApi.getBundlesList({
            projectId,
            locale,
        }, auth);
        if ('errorCode' in response) {
            return Promise.reject(response);
        }
        const bundles = response.items.map((item) => new bundle_1.Bundle(item));
        return bundles;
    }
    static async getGameBySku({ projectId, itemSku, locale = StoreDataService.defaultLocale, }) {
        const response = await store_http_client_1.StoreApi.getGameBySku({
            projectId,
            itemSku,
            locale,
        });
        if ('errorCode' in response) {
            return Promise.reject(response);
        }
        return new unit_1.Unit(response);
    }
    static async getVirtualItemBySku({ projectId, itemSku, locale = StoreDataService.defaultLocale, }) {
        const response = await store_http_client_1.StoreApi.getVirtualItemBySku({
            projectId,
            itemSku,
            locale,
        });
        if ('errorCode' in response) {
            return Promise.reject(response);
        }
        return new virtual_item_1.VirtualItem(response);
    }
    static async getVirtualCurrencyPackageBySku({ projectId, itemSku, locale = StoreDataService.defaultLocale, }) {
        const response = await store_http_client_1.StoreApi.getVirtualCurrencyPackageBySku({
            projectId,
            itemSku,
            locale,
        });
        if ('errorCode' in response) {
            return Promise.reject(response);
        }
        return new pack_1.VirtualCurrencyPack(response);
    }
    static async getBundleBySku({ projectId, itemSku, locale = StoreDataService.defaultLocale, }) {
        const response = await store_http_client_1.StoreApi.getBundleBySku({
            projectId,
            itemSku,
            locale,
        });
        if ('errorCode' in response) {
            return Promise.reject(response);
        }
        return new bundle_1.Bundle(response);
    }
    static async createOrderWithSpecifiedItem({ projectId, itemSku, auth, body, }) {
        const response = await store_http_client_1.StoreApi.createTokenByItem({
            projectId,
            itemSku,
            auth,
            body,
        });
        if ('errorCode' in response) {
            return Promise.reject(response);
        }
        return response;
    }
}
exports.StoreDataService = StoreDataService;
StoreDataService.defaultLocale = 'en';
