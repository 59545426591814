import { FiveSymbolLocale } from '@site-builder/common/src/types/locale';
import { LocalizedValue } from '@site-builder/common/src/types/value/localized-value';
import { StepOfUserInstruction } from '@site-builder/common/src/types/values';

import { getInstructionStepText } from '../../../utils/translation';

const getDescriptionText = ({
  languages,
}: {
  languages: FiveSymbolLocale[];
}): LocalizedValue => {
  const value = { enable: true };

  languages.forEach((language: FiveSymbolLocale) => {
    value[`${language}`] = getInstructionStepText(language);
  });

  return value as LocalizedValue;
};

const imageValue = () => ({
  enable: true,
  type: 'image',
  size: 'contain',
  img: '',
  video: '',
  color: 'rgba(0,0,0,0)',
});

export const createStep = ({ languages }: { languages: FiveSymbolLocale[] }) =>
  ({
    description: getDescriptionText({ languages }),
    image: imageValue(),
  } as StepOfUserInstruction);
