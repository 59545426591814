import { RenderMode } from '@site-builder/common/src/types/build';
import { FiveSymbolLocale } from '@site-builder/common/src/types/locale';
import { Authorization } from '@site-builder/common/src/types/model/landing';
import React, { useState, useEffect, Dispatch } from 'react';
import { IntlShape } from 'react-intl';

import { LOGIN_API } from '../../../config';
import { analyticsManager } from '../../../scripts/afterBlocks/analytics/analytics';
import { AnalyticsActions } from '../../../scripts/afterBlocks/analytics/analyticsActions';
import { AnalyticsCategories } from '../../../scripts/afterBlocks/analytics/analyticsCategories';
import { getCookie } from '../../../scripts/helpers/common';
import { COUNTRY_CODE } from '../../../scripts/helpers/cookies';
import { Button } from '../../../ui-components/Editor/Button';
import { EditorText } from '../../../ui-components/Editor/EditorText';
import { Input } from '../../../ui-components/Editor/Input';
import InstructionCard from '../../../ui-components/Editor/InstructionCard';
import InstructionCut from '../../../ui-components/Editor/InstructionCut';
import { Media } from '../../../ui-components/Editor/Media';
import ModalWindow from '../../../ui-components/Editor/ModalWindow';
import { getConnectedSocials } from '../../../utils/login/api';
import { regionMap } from '../../../utils/login/iso-region-map';
import { SocialInfo } from '../../../utils/login/types';
import { isMobileScreen } from '../../../utils/mobile';
import { reactPopperTooltipLoader } from '../../../utils/module-loader/loaders';
import withAsyncLoaders from '../../../utils/module-loader/with-async-loaders';
import {
  getInputPlaceholderTextForUserIdModal,
  getContinueButtonTextForUserIdModal,
  getInputIncorrectTextForUserIdModal,
  getInputIncorrectCopyPasteTextForUserIdModal,
} from '../../../utils/translation';
import { AllSocialsMedia } from './AllSocialMedia/AllSocialsMedia';
import { Socials } from './Socials/Socials';

import './style.pcss';

enum Status {
  DEFAULT = 'default',
  PROCESSING = 'processing',
  INVALID = 'invalid',
  VALID = 'valid',
}

async function fetchSocials(userIdLoginId: string) {
  const { data: socialsByRegion } = await getConnectedSocials({
    loginId: userIdLoginId,
  });
  if (!socialsByRegion) {
    return [];
  }
  const country: string = getCookie(COUNTRY_CODE);
  const region = (country && regionMap[`${country}`]) || 2;
  return [
    ...socialsByRegion[region].primary,
    ...socialsByRegion[region].secondary,
  ];
}

export interface UserIdModalProps {
  auth: Authorization;
  locale: FiveSymbolLocale;
  intl: IntlShape;
  renderMode: RenderMode;
  setupAssetForMedia: (place: 'background' | 'logo') => () => void;
  showModal: boolean;
  closeModal: () => void;
  handleSave: (auth: Authorization, needSave?: boolean) => void;
  handleSubmitForm?: (inputValue: string, setStatus: Dispatch<Status>) => void;
  handleSaveInstructionText: (value: string) => void;
  setupAssetForInstructionCard: (stepIndex: number) => () => void;
  handleDeleteStep: (stepIndex: number) => () => void;
  handleAddStep: () => void;
  hiddenSocials?: boolean;
  initValue?: string;
}

type Props = {
  popperTooltip: Awaited<ReturnType<typeof reactPopperTooltipLoader>>;
} & UserIdModalProps;

export const UserIdModalViewComponent = ({
  auth,
  locale,
  intl,
  renderMode,
  setupAssetForMedia,
  showModal,
  closeModal,
  handleSave,
  handleSubmitForm,
  handleSaveInstructionText,
  setupAssetForInstructionCard,
  handleDeleteStep,
  handleAddStep,
  hiddenSocials,
  initValue,
  popperTooltip,
}: Props) => {
  const [status, setStatus] = useState<Status>(Status.DEFAULT);
  const [inputValue, setInputValue] = useState('');
  const [socials, setSocials] = useState<SocialInfo[]>([]);
  const [showAllSocials, setShowAllSocials] = useState(false);

  useEffect(() => {
    window.dispatchEvent(new CustomEvent('user-id-modal-event'));
  });

  useEffect(() => {
    if (auth.userIdLoginId && !hiddenSocials) {
      fetchSocials(auth.userIdLoginId).then((socials) => setSocials(socials));
    }
  }, [auth.userIdLoginId]);

  const { usePopperTooltip } = popperTooltip;
  const { getArrowProps, getTooltipProps, setTooltipRef, setTriggerRef } =
    usePopperTooltip({
      placement: isMobileScreen() ? 'top-start' : 'left',
    });

  if (!auth || !showModal) {
    return null;
  }

  const authViaSocialNetwork = (providerName: string) => {
    window.location.href = `${LOGIN_API}/social/${providerName}/login_redirect?projectId=${auth.userIdLoginId}&login_url=${window.location.href}`;
    if (renderMode !== RenderMode.EDITING) {
      analyticsManager.sendEvent({
        category: AnalyticsCategories.UID_WIDGET,
        event: AnalyticsActions.SOCIAL_AUTH,
        page: `landing_uid-widget_${AnalyticsActions.SOCIAL_AUTH}`,
        label: providerName,
      });
    }
  };

  const close = () => {
    if (status === Status.INVALID) {
      setStatus(Status.DEFAULT);
    }
    closeModal();
  };

  const submitForm = () => {
    const value = inputValue.trimEnd().trimStart();
    handleSubmitForm?.(value, setStatus);
  };

  return (
    <ModalWindow
      className='user-id-modal'
      bodyClassName={
        showAllSocials ? 'user-id-modal__social-list' : 'user-id-modal__content'
      }
      isServerSideRender={renderMode !== RenderMode.EDITING}
      enabledBorder
      closeModal={close}
      hidden={false}
      hiddenClose={showAllSocials}
      customBackground={
        auth.background.enable && (
          <Media
            className='custom-background'
            asset={auth.background}
            onMediaButtonClick={setupAssetForMedia('background')}
            renderMode={renderMode}
            isMainImage={false}
            showCustomBackground={auth.background.enable}
            mediaType='background'
          />
        )
      }
    >
      {showAllSocials ? (
        <AllSocialsMedia
          goBackClick={() => setShowAllSocials(false)}
          socials={socials}
          loginBySocial={authViaSocialNetwork}
          locale={locale}
        />
      ) : (
        <>
          {auth.logo.enable && (
            <div className='user-id-modal__logo'>
              <Media
                asset={auth.logo}
                onMediaButtonClick={setupAssetForMedia('logo')}
                renderMode={renderMode}
                isMainImage
                disableLazyLoad
              />
            </div>
          )}
          <div
            className={`user-id-modal__wrapper${
              auth.logo.enable ? '' : '--no-logo'
            }`}
          >
            {socials.length === 0 && (
              <EditorText
                className='user-id-modal__title'
                locale={locale}
                values={auth}
                field='title'
                onBlur={() => handleSave(auth)}
                onCheckoutBlock={() => undefined}
                isServerSideRender={renderMode !== RenderMode.EDITING}
              />
            )}
            {socials.length > 0 && (
              <>
                <Socials
                  socials={socials}
                  loginBySocial={authViaSocialNetwork}
                  openAllSocials={() => setShowAllSocials(true)}
                  locale={locale}
                />
                <div className='user-id-modal__divider-wrapper'>
                  <hr className='user-id-modal__divider' />
                  <EditorText
                    className='user-id-modal__subtitle'
                    locale={locale}
                    values={auth}
                    field='subtitle'
                    onBlur={() => handleSave(auth)}
                    onCheckoutBlock={() => undefined}
                    isServerSideRender={renderMode !== RenderMode.EDITING}
                  />
                  <hr className='user-id-modal__divider' />
                </div>
              </>
            )}
          </div>
          <div className='user-id-modal__input-wrapper' ref={setTriggerRef}>
            <Input
              onChange={setInputValue}
              placeholder={getInputPlaceholderTextForUserIdModal(locale)}
              ariaLabel='Enter user id'
              xaName='user-id-modal-input'
              colorTheme='no-active'
              colorizeBorder={status === Status.INVALID}
              hideButton
              className='user-id-modal__input'
              isLoading={status === Status.PROCESSING}
              initValue={initValue || ''}
            />
          </div>
          {status === Status.INVALID && (
            <div
              ref={setTooltipRef}
              {...getTooltipProps({
                className: 'user-id-modal__input-tooltip-container',
              })}
            >
              {getInputIncorrectTextForUserIdModal(locale)}
              <br />
              {getInputIncorrectCopyPasteTextForUserIdModal(locale)}
              <div
                {...getArrowProps({
                  className: 'user-id-modal__input-tooltip-arrow',
                })}
              />
            </div>
          )}
          <Button
            customType='user-id-button-continue'
            className='user-id-modal__button'
            onClick={submitForm}
            isLoading={status === Status.PROCESSING}
            isEnabledShadow
          >
            <span className='user-id-modal__button-text'>
              {getContinueButtonTextForUserIdModal(locale)}
            </span>
          </Button>
          {auth.instruction.enable && (
            <>
              <InstructionCut
                userInstruction={auth.instruction}
                locale={locale}
                renderMode={renderMode}
                saveInstructionCutText={handleSaveInstructionText}
                onCheckoutBlock={() => undefined}
                isServerSideRender={renderMode !== RenderMode.EDITING}
                inModal
              >
                {auth.instruction.steps.map((step, stepIndex) => (
                  <InstructionCard
                    key={step._id}
                    description={step.description[locale]}
                    renderMode={renderMode}
                    step={step}
                    locale={locale}
                    onCheckoutBlock={() => undefined}
                    saveInstructionStepText={() => handleSave(auth)}
                    onMediaButtonClick={setupAssetForInstructionCard(stepIndex)}
                    deleteStep={handleDeleteStep(stepIndex)}
                    canDelete
                  />
                ))}
              </InstructionCut>
              {renderMode === RenderMode.EDITING && (
                <div className='user-id-modal__tab-step'>
                  <button
                    data-testid='add-step'
                    className='user-id-modal__add-step-button'
                    onClick={handleAddStep}
                  >
                    {intl.messages['editor.topup.addStepButton.text']}
                  </button>
                </div>
              )}
            </>
          )}
        </>
      )}
    </ModalWindow>
  );
};

const asyncProps = {
  popperTooltip: reactPopperTooltipLoader,
};

export const UserIdModalView = withAsyncLoaders(
  UserIdModalViewComponent,
  asyncProps
);
