import { RenderMode } from '@site-builder/common/src/types/build';
import { FiveSymbolLocale } from '@site-builder/common/src/types/locale';

import { SANDBOX_SECURE_HOST, SECURE_HOST } from '../../config';
import { payStationPostMessage } from '../../scripts/afterBlocks/analytics/payStationPostMessages';
import { isSandboxMode } from '../../scripts/helpers/sandbox';
import { getPayStationScript } from '../../scripts/helpers/thirdPartyScripts';

const MOBILE_PAYSTATION_BREAKPOINT = 990;

export const getPayStationUrl = (renderMode: RenderMode) =>
  isSandboxMode(renderMode) ? SANDBOX_SECURE_HOST : SECURE_HOST;
export const isMobile = () => window.innerWidth < MOBILE_PAYSTATION_BREAKPOINT;

const openPayStationIFrame = ({ renderMode, token }) => {
  const iframe = window.document.createElement('iframe');
  iframe.src = `https://${getPayStationUrl(
    renderMode
  )}/paystation/?access_token=${token}`;
  iframe.style.cssText =
    'position:fixed;top:0;bottom:0;left:0;right:0;width:100vw;height:100vh;;z-index:10000;';
  window.document.body.style.overflow = 'hidden';
  const root = window.document.getElementById('root');
  root?.appendChild(iframe);
  payStationPostMessage.handler = (data, command) => {
    if (command === 'close') {
      window.document.body.style.overflow = 'visible';
      iframe.remove();
    }
  };
};

export const openPayStationWidget = async ({
  token,
  renderMode,
  zIndex,
  language,
}: {
  token: string;
  renderMode: RenderMode;
  zIndex?: number;
  language?: FiveSymbolLocale;
}) => {
  if (isMobile()) {
    openPayStationIFrame({ renderMode, token });
  } else {
    const { XPayStationWidget } = await getPayStationScript();
    if (!XPayStationWidget) {
      return;
    }
    const option: any = {
      access_token: token,
      sandbox: isSandboxMode(renderMode),
      host: isSandboxMode(renderMode) ? SANDBOX_SECURE_HOST : SECURE_HOST,
      lightbox: {
        height: '685px',
        spinner: 'round',
      },
    };
    if (zIndex) {
      option.lightbox.zIndex = zIndex;
    }
    if (language) {
      option.language = language;
    }

    XPayStationWidget.init(option);
    XPayStationWidget.open();
  }
};
